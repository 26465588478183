import React from 'react';
import LayoutPage from './Common/LayoutPage';
import Q1 from '../assets/img/q1.png';
import Q2 from '../assets/img/q2.png';
import Q3 from '../assets/img/q3.png';
import Q4 from '../assets/img/q4.png';

function AboutUs() {
  return (
    <LayoutPage>
      <div className='bg-ycustom'>
        <div className='py-20 text-center'>
          <div className='text-black text-4xl md:text-5xl lg:text-6xl font-productSans font-400'>
            Road Map
          </div>
          <div className='text-black xs:text-lg mt-3 text-12px md:text-xl lg:text-2xl w-[90%] md:w-[70%] lg:w-[50%] mx-auto'>
            What’s Coming in the Banana X Universe
          </div>
          <div className='text-black xs:text-lg text-12px md:text-xl lg:text-2xl w-[90%] md:w-[70%] lg:w-[50%] mx-auto'>
            Check out the key milestones as we roll out Banana X
          </div>
        </div>
        <div className='pb-28 pt-10 justify-center flex items-center'>
          <div className='flex flex-wrap justify-around w-full'>
            <div className='w-[350px] xxs:w-[280px] xs:w-[350px]'>
              <div className='flex gap-1 justify-start items-center'>
                <img
                  src={Q1}
                  alt='q1'
                  className='w-[70px] h-[100px] object-contain'
                />
                <div className='text-[20px] font-productSans font-400'>
                  Launch & Airdrop
                </div>
              </div>
              <div className='mt-4 '>
                <ul className='list-disc ml-5  list-outside text-black text-start leading-9'>
                  <li>Telegram mini app goes live</li>
                  <li>Token airdrops & demo trading begins</li>
                  <li>DEX and CEX listings</li>
                  <li>Chapter 1 airdrops</li>
                </ul>
              </div>
            </div>
            <div className='w-[350px] xxs:w-[280px] xs:w-[350px]'>
              <div className='flex w-[265px] justify-start gap-4 items-center'>
                <img
                  src={Q2}
                  alt='q2'
                  className='w-[80px] h-[100px] object-contain'
                />
                <div className='text-[20px] font-productSans font-400'>
                  Banana Exchange Goes Live
                </div>
              </div>
              <div className='mt-4 w-[304px]'>
                <ul className='list-disc ml-5 list-outside text-black text-start leading-9'>
                  <li>Full launch of Banana Exchange</li>
                  <li>Tier 1 partnerships with key platforms</li>
                  <li>Chapter 2 airdrops</li>
                </ul>
              </div>
            </div>
            <div className='w-[350px] xxs:w-[280px] xs:w-[350px]'>
              <div className='flex w-[265px] gap-4 justify-start items-center'>
                <img
                  src={Q3}
                  alt='q3'
                  className='w-[100px] h-[100px] object-contain'
                />
                <div className='text-[20px]  font-productSans font-400'>
                  Advanced Gameplay & Chapter 3 Airdrop
                </div>
              </div>
              <div className='mt-4 w-[304px]'>
                <ul className='list-disc ml-5 list-outside text-black text-start leading-9'>
                  <li>Seasonal events with exclusive rewards</li>
                  <li>Time-limited challenges and branching storylines</li>
                  <li>Chapter 3 airdrops</li>
                </ul>
              </div>
            </div>
            <div className='w-[350px] xxs:w-[280px] xs:w-[350px]'>
              <div className='flex w-[265px] gap-4 justify-start items-center'>
                <img
                  src={Q4}
                  alt='q4'
                  className='w-[100px] h-[100px] object-contain'
                />
                <div className='text-[20px] font-productSans font-400'>
                  Full Tokenization, Competitions & Chapter 4 Airdrop
                </div>
              </div>
              <div className='mt-4  w-[350px] xxs:w-[280px] xs:w-[350px]'>
                <ul className='list-disc ml-5 list-outside text-black text-start leading-9'>
                  <li>Token rewards for completing tasks and trading</li>
                  <li>Exclusive digital collectibles</li>
                  <li>Team-based competitions and tournaments</li>
                  <li>Chapter 4 airdrops</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutPage>
  );
}

export default AboutUs;
