import React from 'react';
import LayoutPage from './Common/LayoutPage';
import Monkey from '../assets/img/m.png';

function JoinCommunity() {
  return (
    <LayoutPage>
      <div className='bg-black'>
        <div className='px-6 py-10 md:px-20 md:py-20 text-start'>
          <div className='text-white text-[28px] md:text-[40px] lg:text-[60px] mb-5 font-productSans font-400'>
            Features of Banana Exchange
          </div>
          <div className='flex flex-col lg:flex-row items-start lg:items-center justify-between'>
            <div className='w-full lg:w-[70%]'>
              <div className='h-auto md:h-[149px] mb-5 bg-ycustom px-4 md:px-8 flex justify-start items-center'>
                <div>
                  <div className='text-[20px] md:text-[26px] font-productSans font-400'>
                    Banana Exchange
                  </div>
                  <div className='text-[14px] md:text-[16px] w-full md:w-[74%] font-productSans font-400'>
                    Watch your points grow as you trade and earn. Soon, you’ll
                    be able to convert them into tokens for real rewards!
                  </div>
                </div>
              </div>
              <div className='flex flex-col md:flex-row mb-5 justify-between items-center'>
                <div className='h-auto md:h-[149px] w-full md:w-[48%] bg-ycustom px-4 md:px-8 flex justify-start items-center mb-5 md:mb-0'>
                  <div>
                    <div className='text-[20px] md:text-[26px] font-productSans font-400'>
                      Spot Trading
                    </div>
                    <div className='text-[14px] md:text-[16px] font-productSans font-400'>
                      Trade tokens instantly at market prices.
                    </div>
                  </div>
                </div>
                <div className='h-auto md:h-[149px] w-full md:w-[48%] bg-ycustom px-4 md:px-8 flex justify-start items-center'>
                  <div>
                    <div className='text-[20px] md:text-[26px] font-productSans font-400'>
                      Copy Trading
                    </div>
                    <div className='text-[14px] md:text-[16px] font-productSans font-400'>
                      Mirror the trades of top traders automatically.
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col md:flex-row mb-5 justify-between items-center'>
                <div className='h-auto md:h-[149px] w-full md:w-[48%] bg-ycustom px-4 md:px-8 flex justify-start items-center mb-5 md:mb-0'>
                  <div>
                    <div className='text-[20px] md:text-[26px] font-productSans font-400'>
                      Meme Token Market
                    </div>
                    <div className='text-[14px] md:text-[16px] font-productSans font-400'>
                      Trade the hottest meme coins in the jungle.
                    </div>
                  </div>
                </div>
                <div className='h-auto md:h-[149px] w-full md:w-[48%] bg-ycustom px-4 md:px-8 flex justify-start items-center'>
                  <div>
                    <div className='text-[20px] md:text-[26px] font-productSans font-400'>
                      Limit & Market Orders
                    </div>
                    <div className='text-[14px] md:text-[16px] font-productSans font-400'>
                      Take control of your trades with precise order types.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-auto lg:ml-10 mt-5 lg:mt-0'>
              <img
                src={Monkey}
                alt='Monkey'
                className='w-[150px] md:w-[200px] lg:w-auto'
              />
            </div>
          </div>
          <div className='text-[18px] md:text-[20px] lg:text-[25.68px] text-ycustom mt-5 font-productSans font-400'>
            Already excited? Practice with demo trading now!
          </div>
          <a
            href='https://t.me/BananaXAppbot'
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer'
          >
            <div className='relative w-full sm:w-[448px] h-[57px] border-[1px] border-white mt-8 cursor-pointer'>
              <div className='absolute left-0 top-0 w-[5px] h-full bg-white'></div>
              <div className='bg-black w-full h-full text-white flex justify-center items-center text-[16px] md:text-[18px] lg:text-[22px] font-productSans font-400'>
                Learn More About Banana Exchange
              </div>
            </div>
          </a>
        </div>
      </div>
    </LayoutPage>
  );
}

export default JoinCommunity;
