import React from 'react';
import LayoutPage from './Common/LayoutPage';
import Gun from '../assets/img/gun.png';
import { ReactComponent as X } from '../assets/x.svg';
import { ReactComponent as Youtube } from '../assets/youtube.svg';
import { ReactComponent as Telegram } from '../assets/telegram.svg';
import YellowX from '../assets/xyellow.png';
import blackline from '../assets/img/line.png';
import { ReactComponent as Email } from '../assets/emailicon.svg';
import { ReactComponent as Facebook } from '../assets/fb.svg';
function Connect() {
  return (
    <LayoutPage>
      <div className='bg-[url(assets/img/bgconnect.png)] bg-cover bg-center border-b-[7px] border-[#F2CF61]'>
        <div className='w-full flex justify-center py-20 md:py-48 items-center'>
          <div className='text-center'>
            <div className='flex justify-center mb-8'>
              <img src={Gun} alt='Gun' className='w-auto h-auto' />
            </div>
            <div className='text-[40px] lg:text-[60px] font-productSans font-400 text-[#ECCA5F] mb-4'>
              Join the Jungle!
            </div>
            <div className='border-b-[1px] border-white border-opacity-50 py-10 mb-4'></div>
            <div className='text-[40px] lg:text-[60px] font-productSans font-400 text-white mb-4 pt-14'>
              Stay Connected
            </div>
            <div className='text-white text-[18px] md:text-[25px] xxs:text-[12.33px] xxs:px-3 xs:max-w-[400px] sm:text-[20px] sm:max-w-[600px] md:max-w-[700px] font-productSans font-400 w-full lg:max-w-[831px] mx-auto'>
              Don’t miss out on any updates. Follow us on socials, join the
              conversation, and get ready for daily shows and exclusive rewards.
            </div>
            <div className='flex justify-center gap-6 mt-10 items-center'>
              <a
                href='https://www.facebook.com/BananaX.meme.official/'
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer'
              >
                <Facebook className='text-white w-[41px] h-[37px]' />
              </a>
              <a
                href='https://x.com/bananaX_meme'
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer'
              >
                <X className='text-white w-[41px] h-[37px]' />
              </a>
              <a
                href='https://t.me/BananaX_channel'
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer'
              >
                <Telegram className='text-white w-[45px] h-[37px]' />
              </a>
              <a
                href='https://www.youtube.com/@bananaX-meme'
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer'
              >
                <Youtube className='text-white w-[52px] h-[36px]' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='w-full h-full bg-black'>
          <div className='flex flex-col md:flex-row justify-center items-center md:justify-around py-10 md:py-20 space-y-10'>
            <div className='text-center md:text-left space-y-4'>
              <a href='#home'>
                <div className='flex items-center justify-center md:justify-start text-[34px] lg:text-[45px] xl:text-[78px] font-productSans font-400 text-white leading-none'>
                  Banana
                  <img
                    src={YellowX}
                    alt='x'
                    className='w-12 h-12 xxs:w-6 xxs:h-6  xl:w-14 xl:h-14 ml-2'
                  />
                </div>
              </a>
              <div className='text-[12px] md:text-[14px] xl:text-[22px] font-productSans font-400 text-white'>
                Trade. Earn. Go Bananas!
              </div>
              <div className='flex justify-start gap-6 mt-10 items-center'>
                <a
                  href='https://www.facebook.com/BananaX.meme.official/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='cursor-pointer'
                >
                  <Facebook className='text-white w-12 h-12 xxs:w-6 xxs:h-6  xl:w-14 xl:h-14' />
                </a>
                <a
                  href='https://x.com/bananaX_meme'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='cursor-pointer'
                >
                  <X className='text-white  w-12 h-12 xxs:w-6 xxs:h-6  xl:w-14 xl:h-14' />
                </a>
                <a
                  href='https://t.me/BananaX_channel'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='cursor-pointer'
                >
                  <Telegram className='text-white w-12 h-12 xxs:w-6 xxs:h-6  xl:w-14 xl:h-14' />
                </a>
                <a
                  href='https://www.youtube.com/@bananaX-meme'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='cursor-pointer'
                >
                  <Youtube className='text-white w-12 h-12 xxs:w-6 xxs:h-6  xl:w-14 xl:h-14' />
                </a>
              </div>
            </div>
            <div className='hidden lg:block'>
              <img src={blackline} alt='blackline' />
            </div>
            <div className='text-center md:text-left'>
              <div className='text-white text-[19.61px] flex gap-10 justify-center md:justify-start font-400 font-productSans'>
                <div>About Us</div>
                <div>Contact Us</div>
              </div>
              <a
                href='mailto:admin@bananax.meme'
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer'
              >
                <div className='flex items-center justify-center gap-4 md:gap-6 mt-10 h-[60px] md:h-[60px] xxs:h-[29px] w-full md:w-[250px] lg:w-[390px] xxs:w-[187px] rounded-[11px] text-[#F2CF61] border-[1px] border-[#F2CF61] p-4'>
                  <Email className='md:w-6 md:h-6 xxs:h-[11px] xxs:w-[11px]' />
                  <span className='text-[12px] md:text-[16px] lg:text-[24px] xxs:text-[12px] font-productSans font-400'>
                    admin@bananax.meme
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </LayoutPage>
  );
}

export default Connect;
