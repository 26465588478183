import React from 'react';

const LayoutPage = ({ children }) => {
  return (
    <div className='w-full bg-white'>
      <div className='max-w-[1920px] mx-auto h-full'>{children}</div>
    </div>
  );
};

export default LayoutPage;
