import './App.css';
import AboutUs from './Components/AboutUs';
import ChooseSite from './Components/ChooseSite';
import Home from './Components/Common/Home';
import Connect from './Components/Connect';
import Divorcetoken from './Components/Divorcetoken';
import JoinCommunity from './Components/JoinCommunity';

function App() {
  return (
    <div>
      <section id='home'>
        <Home />
      </section>
      <section id='chooseSite'>
        <ChooseSite />
      </section>
      <section id='divorcetoken'>
        <Divorcetoken />
      </section>
      <section id='joinCommunity'>
        <JoinCommunity />
      </section>
      <section id='aboutUs'>
        <AboutUs />
      </section>
      <section id='aboutUs'>
        <Connect />
      </section>
    </div>
  );
}

export default App;
