import React, { useEffect, useState } from 'react';
import YellowX from '../../assets/xyellow.png';
import { ReactComponent as X } from '../../assets/x.svg';
import { ReactComponent as Youtube } from '../../assets/youtube.svg';
import { ReactComponent as Facebook } from '../../assets/fb.svg';
import { ReactComponent as Telegram } from '../../assets/telegram.svg';

function Header() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsPopupOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='w-full justify-between items-center flex bg-black h-[85px] px-10 lg:px-10 xxs:px-10'>
      <div className='flex items-center text-center cursor-pointer justify-start '>
        <div className='text-white flex justify-center text-center items-center text-[33px] lg:text-[33px] md:text-[20.84px]  font-productSans font-400  xxs:text-[34px]'>
          Banana
          <img src={YellowX} alt='x' className='w-6 h-6' />
        </div>
      </div>
      <div
        className='md:hidden flex flex-col gap-1 cursor-pointer'
        onClick={togglePopup}
      >
        <div className='w-8 h-[3.55px] rounded-[3.54px] bg-white '></div>
        <div className='w-8 h-[4px] rounded-[3.54px] bg-white '></div>
        <div className='w-8 h-[3.55px] rounded-[3.54px] bg-white'></div>
      </div>
      {isPopupOpen && (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out'>
          <div className='bg-black px-8 pb-8 rounded-lg shadow-lg flex flex-col items-center gap-6 w-3/4 md:w-1/2 lg:w-1/3 animate-slide-down'>
            <div className='w-full justify-end flex'>
              <button
                onClick={togglePopup}
                className='bg-white text-black px-4 py-2 rounded-full mt-6 hover:bg-gray-700 transition-colors duration-200'
              >
                X
              </button>
            </div>
            <a
              href='https://www.facebook.com/BananaX.meme.official/'
              target='_blank'
              rel='noopener noreferrer'
              className='cursor-pointer'
            >
              <Facebook className='text-white sm:w-6 sm:h-6 xxs:w-6 xxs:h-6 ' />
            </a>
            <a
              href='https://x.com/bananaX_meme'
              target='_blank'
              rel='noopener noreferrer'
              className='cursor-pointer'
            >
              <X className='text-white sm:w-6 sm:h-6 xxs:w-6 xxs:h-6 ' />
            </a>
            <a
              href='https://t.me/BananaX_channel'
              target='_blank'
              rel='noopener noreferrer'
              className='cursor-pointer'
            >
              <Telegram className='text-white sm:w-6 sm:h-6 xxs:w-6 xxs:h-6' />
            </a>
            <a
              href='https://www.youtube.com/@bananaX-meme'
              target='_blank'
              rel='noopener noreferrer'
              className='cursor-pointer'
            >
              <Youtube className='text-white sm:w-[30px] sm:h-6 xxs:w-6 xxs:h-6' />
            </a>
            <a
              href='https://t.me/BananaXAppbot'
              target='_blank'
              rel='noopener noreferrer'
              className='cursor-pointer'
            >
              <div className='relative w-[173px] h-[43px] sm:w-[173px] cursor-pointer '>
                <div className='absolute left-0 top-0 w-[5px] h-full bg-white'></div>
                <div className='bg-[#F2CF61] w-full h-full text-black flex justify-center text-[17px] items-center font-productSans font-400 lg:text-[17px] md:text-[16px] xxs:text-[12px]'>
                  Play For Airdrop
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
      <div className='hidden md:flex justify-end text-center items-center'>
        <div className='justify-between gap-10 flex text-center items-center'>
          <a
            href='https://www.facebook.com/BananaX.meme.official/'
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer'
          >
            <Facebook className='text-white sm:w-6 sm:h-6 xxs:w-6 xxs:h-6 ' />
          </a>
          <a
            href='https://x.com/bananaX_meme'
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer'
          >
            <X className='text-white sm:w-6 sm:h-6 xxs:w-4 xxs:h-4 ' />
          </a>
          <a
            href='https://t.me/BananaX_channel'
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer'
          >
            <Telegram className='text-white sm:w-6 sm:h-6 xxs:w-4 xxs:h-4' />
          </a>
          <a
            href='https://www.youtube.com/@bananaX-meme'
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer'
          >
            <Youtube className='text-white sm:w-[30px] sm:h-6 xxs:w-4 xxs:h-4' />
          </a>
          <a
            href='https://t.me/BananaXAppbot'
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer'
          >
            <div className='relative w-[173px] h-[43px] sm:w-[173px] cursor-pointer '>
              <div className='absolute left-0 top-0 w-[5px] h-full bg-white'></div>
              <div className='bg-[#F2CF61] w-full h-full text-black flex justify-center text-[17px] items-center font-productSans font-400 lg:text-[17px] md:text-[16px] xxs:text-[12px]'>
                Play For Airdrop
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
