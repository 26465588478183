import React from 'react';
import Blon from '../../assets/img/drop.png';
import LayoutPage from './LayoutPage';
import Header from './Header';
import Homebg from "../../assets/img/homebg.png"
import YellowX from '../../assets/xyellow.png';
function Home() {
  return (
    <LayoutPage>
      <Header />
      <div className='relative h-full bg-black border-b-4 border-[#F2CF61]'>
        <img
          src={Homebg}
          className='w-full h-full object-cover'
          alt='background'
        />
        <div className='absolute inset-0  xl:py-20 lg:py-10 xxs:py-4'>
          <div className='flex justify-center   '>
            <div className=''>
              <img
                src={Blon}
                alt='b'
                className='w-auto xxs:h-[30px] xs:h-[40px] sm:h-[80px] xl:h-auto lg:h-[100px] md:h-[120px]'
              />
            </div>
          </div>
          <div className='flex flex-col xl:pl-24 lg:pl-12 sm:pl-8 xxs:pl-4 justify-start   '>
            <div>
              <div className='font-productSans md:text-[42px] xl:text-[52px] xxs:text-[16px] xs:text-[24px] font-400 text-white '>
                Welcome to
              </div>
              <div className='flex items-start justify-start '>
                <div className='text-white flex justify-center text-[104px] md:text-[80px] xxs:text-[34px] xs:text-[56px] text-center items-center xl:text-[104px] font-productSans font-400 leading-none  '>
                  Banana
                  <img
                    src={YellowX}
                    alt='x'
                    className=' lg:w-[75px] md:h-[60px] xl:w-[82px] xl:h-[75px] xs:h-[40px] xxs:h-[25px]   '
                  />
                </div>
              </div>
              <div className='font-productSans text-start xl:text-[39px] xxs:text-[16px] xs:text-20px lg:text-[30px] font-400 text-ycustom'>
                Trade. Earn. Go Bananas!
              </div>
              <div className='text-white font-productSans xxs:w-[300px] xs:text-[16px] xl:w-[765px] md:w-[500px] xl:text-[25px] xs:w-[350px]  xxs:text-[10px] lg:text-[22px]  font-400 '>
                Join the wildest adventure on the TON network! Trade to earn
                rewards, complete fun tasks, and dive into the world of meme
                tokens.
              </div>

              <div className='flex justify-start w-full items-start  gap-8 md:gap-8 xxs:gap-2 mt-8 md:mt-6 lg:mt-8 xxs:mt-4 '>
                <a
                  href='https://t.me/BananaXAppbot'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='cursor-pointer'
                >
                  <div className='relative w-[223px] xxs:w-[100px] h-[57px] md:w-[223px] md:h-[57px]  cursor-pointer  xxs:h-[31px]  xxs:text-[12.33px]  md:mx-0 '>
                    <div className='absolute left-0 top-0 w-[5px] h-full bg-white'></div>
                    <div className='bg-[#F2CF61] w-full h-full text-black flex justify-center text-[22px] items-center font-productSans font-400 md:text-[16px] lg:text-[18px] xl:text-[22px]  xxs:text-[10px]'>
                      Start Trading
                    </div>
                  </div>
                </a>
                <a
                  href='https://t.me/BananaXAppbot'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='cursor-pointer'
                >
                  <div>
                    <div className='relative w-[294px] xxs:w-[120px] h-[57px] md:w-[294px] border-[1px] border-white md:h-[57px]    cursor-pointer  xxs:h-[31px]  xxs:text-[12.33px]  md:mx-0 '>
                      <div className='absolute left-0 top-0 w-[5px] h-full bg-white'></div>
                      <div className='bg-black w-full h-full text-white flex justify-center text-[22px] items-center font-productSans font-400 md:text-[16px] lg:text-[18px] xl:text-[22px]  xxs:text-[10px]'>
                        Join Telegram Mini App
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutPage>
  );
}

export default Home;
