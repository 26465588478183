import React from 'react';
import LayoutPage from './Common/LayoutPage';
import Telegram from '../assets/img/telegram.png';
import Demo from '../assets/img/monkey.png';
import Trade from '../assets/img/tread.png';

function Divorcetoken() {
  return (
    <LayoutPage>
      <div className='border-b-4 border-white border-opacity-10 bg-black'>
        <div className='p-20 lg:p-20 xxs:p-10 text-start'>
          <div className='text-white text-4xl md:text-5xl lg:text-6xl pb-10 font-productSans font-400'>
            How it Works
          </div>
          <div className='relative flex w-full mb-10 sm:gap-5 xxs:gap-2 sm:h-[192px] xxs:h-[100px] xxs:rounded-[10px] items-center rounded-[17.45px] border-transparent bg-gradient-to-l from-black to-[#FFDD30]'>
            <div className='absolute inset-[3.5px] z-0 rounded-[7.45px] bg-gradient-to-l from-black to-[#786710]'></div>
            <div
              className='absolute inset-0 z-0 rounded-[17.45px] bg-gradient-to-l'
              style={{
                background:
                  'linear-gradient(-150deg, #000000 20%, transparent 80%)',
              }}
            ></div>
            <div className='relative z-10 sm:pl-5 xxs:pl-2'>
              <img
                src={Telegram}
                alt='Telegram'
                className='xxs:w-9 xxs:h-9 sm:w-auto sm:h-auto'
              />
            </div>
            <div className='relative z-10 text-start'>
              <div className='text-[26px] md:text-[26px] sm:text-[20px] xxs:text-12px text-white font-productSans font-400'>
                Open the Telegram Mini App
              </div>
              <div className='text-[19.57px] xxs:text-10px sm:text-[19px] text-white font-productSans font-400'>
                Join the Banana X community with just a few taps.
              </div>
            </div>
          </div>
          <div className='relative flex w-full mb-10 sm:gap-5 xxs:gap-2 sm:h-[192px] xxs:h-[100px] xxs:rounded-[10px] items-center rounded-[17.45px] border-transparent bg-gradient-to-l from-black to-[#FFDD30]'>
            <div className='absolute inset-[3.5px] z-0 rounded-[7.45px] bg-gradient-to-l from-black to-[#786710]'></div>
            <div
              className='absolute inset-0 z-0 rounded-[17.45px] bg-gradient-to-l'
              style={{
                background:
                  'linear-gradient(-150deg, #000000 20%, transparent 80%)',
              }}
            ></div>
            <div className='relative z-10 sm:pl-5 xxs:pl-2'>
              <img
                src={Trade}
                alt='Trade'
                className='xxs:w-9 xxs:h-9 sm:w-auto sm:h-auto'
              />
            </div>
            <div className='relative z-10 text-start'>
              <div className='text-[26px] md:text-[26px] sm:text-[20px] xxs:text-12px text-white font-productSans font-400'>
                Complete Tasks & Trade
              </div>
              <div className='text-[19.57px] xxs:text-10px sm:text-[19px] text-white font-productSans font-400'>
                Earn points by completing fun challenges or dive into demo
                trading and polish your skills.
              </div>
            </div>
          </div>
          <div className='relative flex w-full mb-10 sm:gap-5 xxs:gap-2 sm:h-[192px] xxs:h-[100px] xxs:rounded-[10px] items-center rounded-[17.45px] border-transparent bg-gradient-to-l from-black to-[#FFDD30]'>
            <div className='absolute inset-[3.5px] z-0 rounded-[7.45px] bg-gradient-to-l from-black to-[#786710]'></div>
            <div
              className='absolute inset-0 z-0 rounded-[7.45px] bg-gradient-to-l'
              style={{
                background:
                  'linear-gradient(-150deg, #000000 20%, transparent 80%)',
              }}
            ></div>
            <div className='relative z-10 xxs:pl-2 sm:pl-5'>
              <img
                src={Demo}
                alt='Demo'
                className='xxs:w-9 xxs:h-9 sm:w-auto sm:h-auto'
              />
            </div>
            <div className='relative z-10 text-start sm:w-[43%] xxs:w-full'>
              <div className='text-[26px] md:text-[26px] sm:text-[20px] xxs:text-12px text-white font-productSans font-400'>
                Convert Points to Tokens
              </div>
              <div className='text-[19.57px] xxs:text-10px sm:text-[19px] text-white font-productSans font-400'>
                Watch your points grow as you trade and earn. Soon, you’ll be
                able to convert them into tokens for real rewards!
              </div>
            </div>
          </div>
          <a
            href='https://t.me/BananaXAppbot'
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer'
          >
            <div className='relative w-[294px] xxs:w-[120px] h-[57px] md:w-[294px] border-[1px] border-white md:h-[57px] cursor-pointer xxs:h-[31px] xxs:text-[12.33px] md:mx-0'>
              <div className='absolute left-0 top-0 w-[5px] h-full bg-white'></div>
              <div className='bg-black w-full h-full text-white flex justify-center text-[22px] items-center font-productSans font-400 md:text-[16px] lg:text-[18px] xl:text-[22px] xxs:text-[10px]'>
                Get Started Now
              </div>
            </div>
          </a>
        </div>
      </div>
    </LayoutPage>
  );
}

export default Divorcetoken;
