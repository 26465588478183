import React from 'react';
import LayoutPage from './Common/LayoutPage';
import Tread from "../assets/img/Trade.png"
import Reward from '../assets/img/reward.png';
import Demo from '../assets/img/demo.png';
import task from '../assets/img/task.png';

function ChooseSite() {
  const data = [
    {
      img: Tread,
      title: 'Trade-to-Earn',
      description:
        'Earn rewards by trading crypto with our unique gamified system.',
    },
    {
      img: task,
      title: 'Tasks & Challenges',
      description:
        'Complete tasks like following socials, subscribing to YouTube, or joining Telegram groups to earn points that convert to tokens.',
    },
    {
      img: Demo,
      title: 'Demo & live Trading',
      description: 'Practice with fake USD before the real action begins.',
    },
    {
      img: Reward,
      title: 'Referral Rewards',
      description: 'Invite your friends and grow your banana stash together!',
    },
  ];
  return (
    <LayoutPage>
      <div className='border-b-4 border-white border-opacity-10 bg-black'>
        <div className='py-20 text-center'>
          <div className='text-[#F2CE60] text-[48px] md:text-[68px] xxs:text-[34px] font-productSans font-400'>
            Why Banana X?
          </div>
          <div className='text-white text-[18px] md:text-[25px] xxs:text-[12.33px] xxs:px-3 xs:max-w-[400px] sm:text-[20px] sm:max-w-[600px] md:max-w-[700px] font-productSans font-400 w-full lg:max-w-[785px] mx-auto'>
            With Banana X, your trading skills determine your rewards. Get ready
            for exclusive token airdrops and the ultimate social-crypto app!
          </div>

          <a href='#joinCommunity'>
            <div className='relative w-[200px] h-[45px] mt-8 mx-auto cursor-pointer xxs:w-[150px] xxs:h-[31px] md:w-[281px] md:h-[57px]'>
              <div className='absolute left-0 top-0 w-[5px] h-full bg-white'></div>
              <div className='bg-[#F2CF61] w-full h-full text-black flex justify-center items-center text-[18px] xxs:text-[12.33px] md:text-[22px] font-productSans font-400'>
                Explore Our Features
              </div>
            </div>
          </a>
        </div>
        <div className='pb-28 '>
          <div className='flex flex-wrap justify-center gap-10 w-full'>
            {data.map((item, index) => (
              <div
                key={index}
                className='relative bg-[#141414] h-[397px] w-[292px] mt-20 p-4 border-b-[10px] border-[#DFB648] rounded-md '
              >
                <div className='absolute -top-24 left-1/2 transform -translate-x-1/2'>
                  <img
                    src={item.img}
                    alt='Card image'
                    className='w-[128px] h-[128] object-cover'
                  />
                </div>
                <div className='mt-12 text-center'>
                  <h3 className='text-white text-[35px] font-productSans font-400'>
                    {item.title}
                  </h3>
                  <p className='text-white text-[22px] font-productSans font-400 mt-4 text-start justify-start'>
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </LayoutPage>
  );
}

export default ChooseSite;
